@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  background: #000;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5932cd; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f2bba; 
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  background: #000;
  background-image: url('../public/images/noise-darker.png');

  overflow-x: hidden;
}

.angry-grid {
  display: grid; 

  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  
  gap: 0px;
  height: 100%;
  
}
 
#item-0 {

  background-size: cover; 
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 4;
  
}
#item-1 {

  background-size: cover; 
  grid-row-start: 2;
  grid-column-start: 5;

  grid-row-end: 4;
  grid-column-end: 10;
  
}
#item-2 {

  background-size: cover; 
  grid-row-start: 4;
  grid-column-start: 2;

  grid-row-end: 7;
  grid-column-end: 4;
  
}
#item-3 {

  background-size: cover; 
  grid-row-start: 5;
  grid-column-start: 5;

  grid-row-end: 7;
  grid-column-end: 9;
  
}

.easeload{
  transition: all 1s ease;
  -webkit-transition: all 1s ease; 
  -moz-transition: all 1s ease; 
  -ms-transition: all 1s ease; 
  -o-transition: all 1s ease; 
}
