
.carousel-track {
    animation: slide 20s linear infinite;
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}